import './style_v2.css'
import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect';

Alpine.plugin(intersect)

window.Alpine = Alpine;

document.addEventListener('DOMContentLoaded', async () => {
  Alpine.start();
});